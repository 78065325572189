
import { defineComponent, onMounted } from 'vue';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import axios from 'axios';
import { ElNotification } from 'element-plus';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'course-wise-progress-report',
  components: {
    axios,
    Swal,
    // Dropdown1,
  },
  data() {
    return {
      batch: {
        entity_id: '' ,
        tranche_id: this.$route.params.tranche,
        course_category: '',
        course_type: '',
        start_date: '',
        end_date: '',
      } as any,
      edc:[] as any,
      entityInfos: [],
      tranches_info: [],
      courseTypes: [],
      categoryList: [],
      componentKey: 0,
      courseData: [],
      is_motor_driving: '' as any,
      reportData: [],
      loading: false,
    };
  },
  async created() {
    this.batch.entity_id = this.$route.params.entity as number | string;
this.edc = [36, 37, 39, 43];

let isEntityFound = false;

for (let i = 0; i < this.edc.length; i++) {
    if (this.edc[i] == this.batch.entity_id) {
        isEntityFound = true;
        break;
    }
}

if (isEntityFound) {
  this.is_motor_driving = 1;
} else {
    console.log('dhukse bal');
      this.is_motor_driving = this.$route.params.is_motodriving as number | string;
}
    await this.getTranches();
    await this.getEntityInfos();
    await this.getCourseTypes();
    await this.getCategoryTypeList();
    if (this.batch.entity_id) {
      await this.getCourseProgress();
    }
  },
  methods: {
    async printPdf() {
      let formData = new FormData();
      if (this.batch.entity_id) {
        let is_motor_driving = '';
        if (this.is_motor_driving == '1') {
          is_motor_driving = '1';
        } else {
          is_motor_driving = '';
        }
        let tranche_id = this.batch.tranche_id;
        if (this.batch.tranche_id == '0') {
          tranche_id = '';
        }

        formData.set('tranche_id', tranche_id);
        formData.set('entity_id', this.batch.entity_id);
        formData.set('course_category', this.batch.course_category);
        formData.set('course_type', this.batch.course_type);
        formData.set('is_motor_driving', is_motor_driving);
        formData.set('start_date', this.batch.start_date);
        formData.set('end_date', this.batch.end_date);
        let data = `${this.VUE_APP_API_URL}/api/report/trainingprogressReport_print`;

        axios
          .post(data, formData)

          .then((response) => {
            //window.open(data, '_blank');
            document.write(response.data);
            window.print();
            location.reload();
            //open the new window and write your HTML to it
          });
      }
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      if (this.batch.entity_id) {
        let is_motor_driving = '';
        if (this.is_motor_driving == '1') {
          is_motor_driving = '1';
        } else {
          is_motor_driving = '';
        }
        let tranche_id = this.batch.tranche_id;
        if (this.batch.tranche_id == '0') {
          tranche_id = '';
        }

        formData.set('tranche_id', tranche_id);
        formData.set('entity_id', this.batch.entity_id);
        formData.set('course_category', this.batch.course_category);
        formData.set('course_type', this.batch.course_type);
        formData.set('is_motor_driving', is_motor_driving);
        formData.set('start_date', this.batch.start_date);
        formData.set('end_date', this.batch.end_date);

        await ApiService.post('report/trainingprogressReport_pdf', formData)
          .then((response) => {
            Swal.fire({
              title: 'Download Successfull!',
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'View/Download PDF',
            }).then((result) => {
              if (result.isConfirmed) {
                // redirect to new tab

                let path = `${this.VUE_APP_API_URL}/${response.data}`;

                window.open(path, '_blank');
              }
            });
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          text: 'Please select all required field!',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        });
      }
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      if (this.batch.entity_id) {
        let is_motor_driving = '';
        if (this.is_motor_driving == '1') {
          is_motor_driving = '1';
        } else {
          is_motor_driving = '';
        }
        let tranche_id = this.batch.tranche_id;
        if (this.batch.tranche_id == '0') {
          tranche_id = '';
        }

        formData.set('tranche_id', tranche_id);
        formData.set('entity_id', this.batch.entity_id);
        formData.set('course_category', this.batch.course_category);
        formData.set('course_type', this.batch.course_type);
        formData.set('is_motor_driving', is_motor_driving);
        formData.set('start_date', this.batch.start_date);
        formData.set('end_date', this.batch.end_date);
        axios
          .post(
            `${this.VUE_APP_API_URL}/api/report/trainingprogressReport_export`,
            formData,
            {
              responseType: 'blob',
            }
          )
          .then((response) => {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: 'application/vnd.ms-excel',
              })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'trainingprogressReport.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch((response) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          text: 'Please select all required field!',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        });
      }
    },
    async getTranches() {
      await ApiService.get('tranche_list')
        .then((response) => {
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getEntityInfos() {
      this.loading = true;
      await ApiService.get('entity_list')
        .then((response) => {
          this.loading = false;
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getCourseTypes() {
      await ApiService.get('course_types')
        .then((response) => {
          this.courseTypes = response.data.data;
          this.courseTypes.length = 2;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCategoryTypeList() {
      await ApiService.get('course_category_list')
        .then((response) => {
          this.categoryList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getCourseProgress() {
      if (this.batch.entity_id) {
        let is_motor_driving = '';
        if (this.is_motor_driving == '1') {
          is_motor_driving = '1';
        } else {
          is_motor_driving = '';
        }
        let tranche_id = this.batch.tranche_id;
        if (this.batch.tranche_id == '0') {
          tranche_id = '';
        }
        this.loading = true;
        ApiService.get(
          'course-wise-progress?tranche_id=' +
            tranche_id +
            '&entity_id=' +
            this.batch.entity_id +
            '&course_category=' +
            this.batch.course_category +
            '&course_type=' +
            this.batch.course_type +
            '&start_date=' +
            this.batch.start_date +
            '&end_date=' +
            this.batch.end_date +
            '&is_motor_driving=' +
            is_motor_driving
        )
          .then((response) => {
            this.loading = false;
            this.reportData = response.data.data;
            this.courseData = response.data.data.courseData;
            this.componentKey += 1;
          })
          .catch((response) => {
            this.loading = false;
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          text: 'Please select training partner!',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        });
      }
    },
  },
});
